// Custom global scripts
/**
 * watchAwaitSelector
 * * Uses mutation observer to watch for 'selector' involved in DOM changes (added, removed, class change, content change, etc)
 * @param {Function} callback A callback function to be run whenever DOM changes are detected, can be anonymous or named
 * @param {String} selector Element(s) to look for involved in DOM changes, you can watch multiple selectors by separating via comma '.class1, .class2, #id3'
 * @param {Node} rootNode Node to watch from, typically document
 * @param {Number} fallbackDelay Interval to be used if the browser does not support MutationObserver, value is in milliseconds (2000 = 2 seconds)
 */
const awaitSelector = (selector, rootNode, fallbackDelay) => new Promise((resolve, reject) => {
  try {
    const root = rootNode || document
    const ObserverClass = MutationObserver || WebKitMutationObserver || null
    const mutationObserverSupported = typeof ObserverClass === 'function'
    let observer
    const stopWatching = () => {
      if (observer) {
        if (mutationObserverSupported) {
          observer.disconnect()
        } else {
          clearInterval(observer)
        }
        observer = null
      }
    }
    const findAndResolveElements = () => {
      const allElements = root.querySelectorAll(selector)
      if (allElements.length === 0) return
      const newElements = []
      const attributeForBypassing = 'data-awaitselector-resolved'
      allElements.forEach((el, i) => {
        if (typeof el[attributeForBypassing] === 'undefined') {
          allElements[i][attributeForBypassing] = ''
          newElements.push(allElements[i])
        }
      })
      if (newElements.length > 0) {
        stopWatching()
        resolve(newElements)
      }
    }
    if (mutationObserverSupported) {
      observer = new ObserverClass(mutationRecords => {
        const nodesWereAdded = mutationRecords.reduce(
          (found, record) => found || (record.addedNodes && record.addedNodes.length > 0),
          false
        )
        if (nodesWereAdded) {
          findAndResolveElements()
        }
      })
      observer.observe(root, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeOldValue: true,
        characterData: true,
        characterDataOldValue: true
      })
    } else {
      observer = setInterval(findAndResolveElements, fallbackDelay || 250)
    }
    findAndResolveElements()
  } catch (exception) {
    reject(exception)
  }
})

const watchAwaitSelector = (callback, selector, rootNode, fallbackDelay) => {
  (function awaiter(continueWatching = true) {
    if (continueWatching === false) return
    awaitSelector(selector, rootNode, fallbackDelay)
      .then(callback)
      .then(awaiter)
  }())
}

jQuery(document).ready(function ($) {
  'use strict';

  // Foundation Init
  $(document).foundation();


  //hide slider arrows if only one slide
  watchAwaitSelector(
    function () {
      const slides = $(".js-carrousel__content");
      if (slides && slides.length == 1) {
        $('.js-carrousel__button-container').hide();
        const carousel = document.querySelector('.js-carrousel__content');
        // ADA requirements if only 1 slide
        carousel.removeAttribute("role");
        carousel.removeAttribute("aria-labelledby");
      }
    },
    '.js-carrousel__button-container',
    document,
    1500
  );

  watchAwaitSelector(
    function () {
      $('.submit-btn').click(function () {
        $('#contact-section-title').html("Thank You! Your submission has been received.");
      });
    },
    '.submit-btn',
    document,
    1500
  );

}(jQuery));

let loadButton = document.querySelector('.alm-load-more-btn');
let initialCard = document.querySelectorAll('.initial-card');
if (loadButton) {
  loadButton.addEventListener('click', () => {
    for (let i = 0; i < initialCard.length; i++) {
      initialCard[i].style.display = 'none';
    }
  })
}


const shareButton = document.querySelector('.share-button');
const shareDialog = document.querySelector('.share-dialog');
const closeButton = document.querySelector('.close-button');

if (shareButton) {
  shareButton.addEventListener('click', event => {
    if (navigator.share) { 
      navigator.share({
         title: document.title,
         url: window.location.href
       }).then(() => {
         console.log('Thanks for sharing!');
       })
       .catch(console.error);
       } else {
           shareDialog.classList.add('is-open');
       }
  });
}


if (closeButton) {
  closeButton.addEventListener('click', event => {
    shareDialog.classList.remove('is-open');
  });
}

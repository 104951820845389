import $ from "jquery";
import slick from 'slick-carousel';

var $slickElement = $(".vertical-slider");
var $statusCurrent = $(".pagingInfoCurrent");
var $statusTotal = $(".pagingInfoTotal");
$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $statusCurrent.text(i);
    $statusTotal.text(slick.slideCount);
    
    if(i == 1){
        $statusCurrent.css("opacity", "0.5");
        $(".vertical-prev").css("opacity", "0.5");
        document.querySelector('.dot').classList.add('white-dot-half-up');
        document.querySelector('.dot').classList.remove('white-dot-half-down');
        document.querySelector('.dot').classList.remove('white-dot-full');
    } else if(i == slick.slideCount){
        $statusTotal.css("opacity", "0.5");
        $(".vertical-next").css("opacity", "0.5");
        document.querySelector('.dot').classList.remove('white-dot-half-up');
        document.querySelector('.dot').classList.add('white-dot-half-down');
        document.querySelector('.dot').classList.remove('white-dot-full');
    } else{
        $statusCurrent.css("opacity", "1");
        $statusTotal.css("opacity", "1");
        $(".vertical-prev").css("opacity", "1");
        $(".vertical-next").css("opacity", "1");
        document.querySelector('.dot').classList.remove('white-dot-half-up');
        document.querySelector('.dot').classList.remove('white-dot-half-down');
        document.querySelector('.dot').classList.add('white-dot-full');
    }
  });

if ($slickElement.length) {
    $(".vertical-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        infinite: false,
        dots: false,
        arrows: true,
        prevArrow: $(".vertical-prev"),
        nextArrow: $(".vertical-next"),
        vertical: true,
    });
}

$(window).resize(function(){
    $('.vertical-slider')[0].slick.refresh();
  });

var $postsSlider = $(".posts-slider");

if ($postsSlider.length) {
    $(".posts-slider").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        infinite: true,
        dots: false,
        arrows: true,
        prevArrow: $('.prev'),
        nextArrow: $('.next'),
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
              }
            },
            
          ]
    });
}

$(window).resize(function(){
    $('.posts-slider')[0].slick.refresh();
  });